import { stubFalse } from 'lodash';
import { isTokenExpired } from '@/helpers/auth';
import { api, authPrefix, tenant, name } from '@/constants/environment';
import { TECHNICAL_ASSISTANCE } from '@/constants/quotationKind';
import { CLIENT, EXPERT } from '@/constants/userKind';
import { FR, EN } from '@/constants/locales';
import { MY_MISSIONS, MISSIONS_MATCHING } from '@/constants/router_base';
import { POST_SIGNUP, PROFILE } from '@/constants/features';
import { DAY } from '@/constants/costPerKind';
import {
  Review,
  Published,
  Archived,
} from '@/constants/fields/missionStatus_default';
import {
  ARCHIVE_REASON_CANCELLED_BY_CLENT,
  ARCHIVE_REASON_CANCELLED_BY_OWNER_WITH_USER,
  ARCHIVE_REASON_WON,
} from './constants/mission/archiveReasons';
import {
  MY_APPLICATIONS_LINK,
  MY_MISSIONS_LINK,
  MY_TRAININGS_LINK,
} from './constants/Header/navItems';
import { BEGINNING } from '@/constants/positions';
import { DARK_SECONDARY } from '@/constants/cssButtonVariant';
import { DISCLAIMER_WITH_VIDEO } from './constants/mission/disclaimer';

export default {
  name: tenant,
  displayName: name,
  lang: [FR, EN],
  api,
  authPrefix,
  registerPrefix: tenant,
  algoliaPrefix: tenant,
  herokuPrefix: tenant,
  homePath: {
    client: MY_MISSIONS,
    expert: MISSIONS_MATCHING,
  },
  global: {
    openOffer: true,
    linkedinLogin: true,
    ssoLogin: false,
    resumeSync: true,
    hasMixedUsers: false,
    signUpMixed: false,
    showBookmarks: (userKind) => !userKind || userKind === CLIENT,
    showClientPool: () => false,
    showProfile: () => true,
    showNda: () => false,
    showClientPools: () => false,
    showProposals: false,
    showRelevance: true,
    authView: true,
    defaultCountry: 'FR',
    tawkTo: true,
    conditionalCategories: false,
    viralLoops: false,
    maxSizeAttachment: 25000000,
    expertSearchAccess: true,
    showCaseBarOffline: false,
    barRegistration: false,
    landingLinkInvitationsBanner: {
      enable: false,
      link: '#',
    },
    showAssistance: false,
    showBilling: false,
    showTrainings: (userKind) => userKind === EXPERT,
    meltingSpot: () => false,
    homePathOpenModal: {
      enabled: true,
      modalName: 'matches_video_disclaimer',
    },
  },
  header: {
    expert: [MY_APPLICATIONS_LINK, MY_TRAININGS_LINK],
    client: [MY_MISSIONS_LINK],
    partner: null,
  },
  contract: {
    disabled: true,
    tabs: [],
  },
  conversation: {
    // handle the section which display information about relevance score.
    // Ex: Used to inform why an expert can not use conversation feature
    relevanceScore: {
      show: true, // show or not RelevanceScoreInformation
      icon: true, // show or not icon on RelevanceScoreInformationModal
      maxScore: 2, // step to achieve to unlock feature, for kicklox, 2 === Giga
    },
    actionButtonColor: DARK_SECONDARY,
    noteColor: DARK_SECONDARY,
  },
  search: {
    showExperts: (userKind) => !userKind || userKind === CLIENT,
    showClients: stubFalse,
    showMixed: stubFalse,
    showCompanies: (userKind) => !userKind || userKind === CLIENT,
    showMissions: (userKind) => !userKind || userKind === EXPERT,
    restrictedAccess: true,
    restrictSearchableAttributes: (userKind) => userKind === CLIENT,
    tooMuchHitsWording: true,
    showAllResultsButton: () => true,
  },
  matching: {
    showSkills: true,
    showJob: true,
    showRemuneration: true,
    showLocation: true,
    showSectors: true,
    showDisclaimer: {
      display: true,
      content: DISCLAIMER_WITH_VIDEO,
    },
    showVideoLinkIfNoResult: true,
  },
  login: {
    showHeadline: true,
  },
  kanban: {
    showMultipleRecruiters: false,
    showRecommendedBy: true,
    lockForReviewMission: false,
    customInformationMessage: false,
    showMissionContext: false,
  },
  postSignup: {
    hasAsideDescription: true,
    showPersonalSkills: true,
    requiredPersonalSkills: false,
  },
  postSignupCvParsing: {
    showAtThe: BEGINNING,
    hasAsideDescription: true,
  },
  settings: {
    showAvatar: () => true,
    showName: () => true,
    showEmail: () => true,
    showPhone: () => true,
    showJob: () => true,
    userJobRequired: true,
    userPhoneRequired: true,
    showEmailNotification: true,
    showContractNotification: false,
    showInvoiceNotification: false,
    showLinkedinUrl: true,
    showMembers: true,
    showDocuments: true,
    showCompany: true,
    clientDetailsSection: false,
    showN_1Revenues: false,
    requiredRCS: false,
    requiredNAF_APE: false,
    showRCS: true,
    showNAF_APE: true,
    requiredSIRET: true,
    barRegistrationSection: false,
    showSIRET: true,
    requiredDescription: true,
    requiredCompanyCreated: true,
    showSirenSection: true,
    showActivityNotifications: true,
    showConversations: true,
    showClientPool: false,
    showLanguages: true,
    showRepresentativeSection: (userKind) => userKind === EXPERT,
  },
  mission: {
    showCompanyLogo: true,
    showAbout: true,
    showApply: (userKind) => userKind === EXPERT,
    showApplyMixed: () => false,
    showContext: true,
    showOwnership: () => false,
    // handle the section which display information about relevance score.
    // Ex: Used to inform why an expert can not candidate to a mission
    relevanceScore: {
      show: true,
      maxScore: 3, // step to achieve to unlock feature, for kicklox, 2 === Giga
    },
    showDomains: false,
    showNdaSection: false,
    showCompanyConfidentialitySection: true,
    experienceTime: { min: 0, max: 30 },
    nameLimitedList: false,
    showOutsourcing: true,
    applyWithAttachment: true,
    applyWithSalary: true,
    apply: {
      candidateWithAttachment: true,
    },
    partnerOffer: false,
    openOffline: true,
    showDescription: true,
    showEngineerSection: true,
    requiredLanguages: true,
    requiredCategories: false,
    showMissionSalaryConfidentiality: false,
    showOthersModalities: false,
    customInformationModal: false,
    tabsStatuses: [Published, Review, Archived],
    archiveReasons: (user) => [
      ARCHIVE_REASON_WON,
      ARCHIVE_REASON_CANCELLED_BY_CLENT,
      ARCHIVE_REASON_CANCELLED_BY_OWNER_WITH_USER(user),
    ], // radio list of reason of archiving in ArchiveMissionModal.js
    startDateConditionedByContractType: true,
    endDateConditionedByContractType: true,
    contextConditionedByContractType: true,
    companyLevelConditionedByContractType: true,
    locationPlaceConditionedByContractType: true,
    modalitiesConditionedByContractType: true,
    locationPlaceWithDetails: false,
    showCompanySection: true,
    redirectCandidacy: false,
    showDisclaimer: true,
    showMatchingScore: false,
    showConsultation: false,
    card: {
      showSubTitleSubJobLocation: false,
      showBillingModality: false,
      showTotalDutyModality: false,
      showStartDateModality: false,
      showLocationModality: true,
      showCandidaciesInKanbanForExpert: true,
    },
    skills: {
      withFavorite: true,
      optionalSkills: {
        minLength: null,
        required: false,
      },
      personalSkills: {
        show: true,
      },
    },
    showDeliverables: false,
    showContextField: false,
    showKindSection: false,
    showRecruitment: false,
    showContractTypes: true,
    showBillingMethod: false,
    showTools: true,
  },
  company: {
    showKind: false,
    showExperience: (kind) => kind === EXPERT,
    showCategories: (companyKind) => companyKind === EXPERT,
    showSkills: true,
    showReview: false, // TODO change for true when feature will be ready
    showCertifications: true,
    showEquipments: false,
    showExperiences: true,
    showDegrees: true,
    showLanguages: true,
    showPlatformRecommendation: true,
    showRecommendationProfileButton: true, // TODO wil be removed when feature will be ready
    showMembersButton: false, // TODO wil be removed when feature will be ready
    showEntities: false,
    showManager: false,
    showCompetenciesFile: true,
    showCandidacy: true,
    canDownloadPdf: true,
    canDownloadDocx: true,
    showBookmarks: (companyKind) => companyKind === EXPERT,
    showCollaborate: true,
    showConverse: false,
    showExpertises: false,
    showIndividualCertificationSkills: false,
    showKeywords: false,
    showAdminCertificationStatus: true,
    // Extended Cv upload feature allow user to choose if he wants to display his
    // cv on his profile or not. It also allow user to choose if the cv parsing should override
    // his own profile infos
    extendedCvUpload: false,
    updateProfileCvUpload: true,
    showCompanySalaryConfidentiality: true,
    showAdminSection: true,
    dailyRate: { min: 200, max: 1500 },
    budgetRate: { min: 0, max: 50000 },
    salary: { min: 20000, max: 150000, step: 1000 },
    experienceTime: { min: 0, max: 30 },
    experienceTimeRestriction: () => true,
    showLinkedinField: true,
    jobNameLimitedList: false,
    companyJobNameLimitedList: false,
    certificationEntitledLimitedList: false,
    showLegalInfoInAdminSection: false,
    showCollaborators: false,
    expertCompanyKindIsSolo: false,
    showPracticeCulture: false,
    contractTypeIsAlwaysMissions: false,
    hourlyRate: { min: 70, max: 2500 },
    showHourlyRate: false,
    showFinishedMissions: false,
    showExpTimeForMixed: false,
    showClientActions: false,
    showInterviewsConduct: true,
    showJoinUs: true,
    showCompanyValues: true,
    showSocialNetworks: true,
    showCategoriesAboveSectors: true,
    tools: {
      expert: { show: false },
      client: { show: true },
    },
    maxRelevanceScore: 3,
    showRewards: true,
  },
  sectors: {
    sorting: true,
  },
  // Configuration for Skills (@/components/Skills)
  skills: {
    // Configuration for @/components/Skills/components/SkillRating
    rating: {
      activeFeatures: [POST_SIGNUP, PROFILE], // features which will use SkillRating
      max: 4,
    },
    // Configuration for @/components/Skills/components/SkillsView
    highlightMax: 5, // corresponds to the number of the highlighted skills ( = weight more important)
    limitedList: false, // limited list skills to select (in profile, project, experience, degree, certification, mission, etc...)
    // TO REMOVE !
    // swim uses optional skill as personnal
    // to not create more confusion, optional
    // skills must be replace by personnal skills in mission form
    optionalSkillsIsPersonalSkills: true,
    kindRequestTagPersonalSkill: 'personal_skill',
    withRatingLetters: false,
    name: {
      maxLength: 60,
      invalidCharactersRegex: /[•\\,;«»"°_@=%€$£*§|]/,
    },
  },
  equipments: {
    limitedList: false,
  },
  proposal: {
    showQuote: {
      billingChoice: true,
    },
    defaultBilling: TECHNICAL_ASSISTANCE,
    displayPaymentPeriod: true,
    displayIndemnity: true,
    professionalIndemnity: true,
    workDays: { min: 1, max: 5, step: 1 },
    workHours: { min: 1, max: 14, step: 1 },
    costPer: DAY,
    displayDiscountEqualZero: true,
    paymentPeriodChoices: {},
  },
  milestone: {
    hoursWorked: { min: 0, max: 10000, step: 1 },
  },
  notifications: {
    displayMissionName: true,
  },
  invoice: {
    proformaActivated: false,
  },
  candidacy: {
    showVideoInApplyModal: true,
  },
  routes: {
    home: { authenticated: false },
    signup: {
      restricted: false,
      clientPhoneField: true,
      expertPhoneField: false,
      footer: false,
      requiredCompanyNameField: true,
      displayCompanyNameField: true,
    },
    login: {},
    forgotPassword: {},
    resetPassword: {},
    confirmEmail: { enable: true },
    resendEmailConfirmation: { enable: true },
    logout: {},
    postSignup: {},
    missionCreator: {},
    missions: {
      authenticated: false,
      footer: () => isTokenExpired(),
    },
    mission: {
      authenticated: false,
    },
    proposalCreator: {},
    proposals: {},
    proposal: {},
    experts: {
      authenticated: true,
      footer: () => isTokenExpired(),
    },
    expert: {},
    clientPool: { restricted: CLIENT },
    clientPools: { restricted: true },
    clients: { restricted: true },
    client: { restricted: true },
    profile: {},
    bookmarks: { restricted: CLIENT },
    bookmark: { restricted: CLIENT },
    settings: {},
    admin: {},
    myMissions: {},
    myMissionBoard: {},
    myMissionProposals: {},
    myMissionMatches: {},
    myMissionEditor: {},
    myApplications: {},
    conversations: {},
    contracts: { restricted: false },
    invoices: {
      restricted: false,
      bannerMessage: true,
    },
    contract: { restricted: false },
    nda: {},
  },
  validation: {
    mission: {
      minSkillsLength: 5,
      maxSkillsLength: null,
      maxSectorsLength: null,
      maxSubCategoriesLength: null,
      maxCategoriesLength: null,
      minIndividualCertificationSkillsLength: 3,
      minFavoriteLength: null,
    },
    expertProfile: {
      minSkillsLength: 5,
      maxOptionalSkillsLength: null,
      minOptionalSkillsLength: null,
      maxSectorsLength: null,
      maxSubCategoriesLength: null,
      maxCategoriesLength: null,
      minIndividualCertificationSkillsLength: 3,
    },
    general: {
      maxCategoriesWithFavorite: null,
    },
  },
};
